/* 容器設置 */
.profile-container {
    position: relative; /* 確保 modal 相對於這個容器定位 */
  }
  
  /* 頭像按鈕樣式 */
  .profile-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  /* 頭像樣式 */
  .profile-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  /* 彈出選單樣式 */
  .profile-modal {
    position: absolute;
    top: 100%; /* 緊貼按鈕下方 */
    right: 0; /* 靠右對齊 */
    width: 250px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px 0;
    margin-top: 20px;
  }
  
  .modal-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .uid-button {
    margin-left: auto;
    padding: 4px 8px;
    background: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* 選單列表 */
  .modal-menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .modal-menu li {
    font-size: 14px;
    padding: 10px 16px;
    cursor: pointer;
    /* border-bottom: 1px solid #f9f9f9; */
    margin: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
  }
  
  .modal-menu li:hover {
    background: #ebe9e9;
  }

  .header-profile-title{
    font-size: 14px;
    font-weight: bolder;
    margin: 10px 0px 5px 15px;
  }

  .header-profile-name {
    display: inline-flex;
    vertical-align: middle; /* 與旁邊的文字垂直對齊 */
    margin-right: 10px;
    font-size: 16px;
    color: white;
    background-color: #666; /* 替代顏色 */
    width: 43px;
    height: 43px;
    border: 2px solid #f58529; /* 頭像外圈顏色 (Instagram 漸層效果) */
    border-radius: 50%;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    text-align: center;
}

.header-profile-name:hover{
    border-radius: 50%;
    width: 43px;
    height: 43px;
    background-color: #e90e0e;
    border: 2px solid #f58529; /* 頭像外圈顏色 (Instagram 漸層效果) */
}

.header-profile-name:active {
    background-color: #f9f9f9;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    border: 2px solid #f58529; /* 頭像外圈顏色 (Instagram 漸層效果) */
}

.header-setting-profile-name {
    display: inline-flex;
    vertical-align: middle; /* 與旁邊的文字垂直對齊 */
    margin-right: 10px;
    font-size: 12px;
    color: white;
    background-color: #666; /* 替代顏色 */
    width: 25px;
    height: 25px;
    border: 2px solid #f58529; /* 頭像外圈顏色 (Instagram 漸層效果) */
    border-radius: 50%;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    text-align: center;
}
  