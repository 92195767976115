.highlight-box {
    background-color: #d8f8f0;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
}

.highlight-box.orange {
    background-color: #ffe8d6;
}

.job-card {
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.job-card .icon {
    color: #ff5a5a;
}

.video-frame {
    margin-top: 30px;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.homeTitle{
    color:black;
    font-size: 48px;
    font-weight: bold;
}

.homeSubTitle{
    color:black;
    margin-top: 10px;
    font-size: 20px;
}

.hero-section {
    background-size: cover;
    background-position: center;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.input-custom{
    padding: 6px;
    border-radius: 6px;
    border: 1px solid rgb(204, 204, 204);
    margin-top: 100px;
}

.btn-subscribe{
    height: 50px; /* 設置固定高度 */
    line-height: 50px; /* 與高度相等，讓文字居中 */
    font-size: 16px; /* 設置字體大小 */
}

.landing-action-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    height: 140%;
}

.input-custom{
    margin-top: 10px;
}

.landing-action-btn {
    margin-top: 100px;
}

@media (max-width: 600px) {
    .homeTitle{
        color:black;
        font-size: 40px;
        font-weight: bold;
    }

    .homeSubTitle{
        color:black;
        font-size: 20px;
        margin-top: 5px;
    }


    .btn-cta {
        background-color: black;
        color: white;
        padding: 12px 24px;
        border-radius: 50px;
        font-weight: bolder;
        margin-right: 50px;
    }
    
    .btn-cta-secondary {
        background-color: #FEF58B;
        color: black;
        padding: 12px 24px;
        border-radius: 50px;
        font-weight: bolder;
    }


    .video-frame {
        margin-top: 30px;
        border-radius: 15px;
        overflow: hidden;
        width: 100%;
        height: 70%;
    }

    .hero-section {
        background-size: cover;
        background-position: center;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 100px;
    }

    .landing-action-content {
        background-color: #fff;
        padding: 30px;
        border-radius: 12px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
        margin-top: 100px;
        margin-bottom: 10px;
        height: 100%;
    }
}