.interviewrating{
    background-color: #f4b9b9;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px; 
    border-radius: 5px;
    margin-top: 10px;
}

.noreply{
    background-color: #f1f6bd;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px; 
    border-radius: 5px;
    margin-top: 10px;
}

.difficulty{
    background-color: #bdf6bf;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px; 
    border-radius: 5px;
    margin-top: 10px;
}

.interviewtime{
    background-color: #b0d3fb;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px; 
    border-radius: 5px;
    margin-top: 10px
}
.whyInterview{
    background-color: #d4eac4;
    border-radius: 5px;
    padding:5px;
}

.overlap-container {
    position: relative;
    /* Make the parent div relative */
    background-color: #f9f9f9;
    border: 1px solid #ccc;
}

.hideContent {
    z-index: 1;
    /* Ensure the paragraph is on top */
}

.hideDiv{
    margin-top: 30px;
                        border-radius: 15px; 
                        height: 200px; 
                        background-color: #f0f0f0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
}

.blurred-div p {
    filter: blur(3px);
    /* Add blur to the text */
    transition: filter 0.3s ease-in-out;
}

.blurred-div h5 {
    filter: blur(5px);
    /* Add blur to the text */
    transition: filter 0.3s ease-in-out;
}

.overlap-container button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Center the button */
    z-index: 2;
    /* Ensure the button is above the paragraph */
}

.hideContentBtn{
    border-radius: 50px;
    background-color: #bdf6bf;
    padding: 10px 50px;
    z-index: 1;
}

.hideContentBtnNone{
    display: none;
}

.hearts {
    font-size: 15px; /* 控制愛心的大小 */
    line-height: 1; /* 縮小上下間距 */
    margin-top: 10px;
    margin-bottom: 5px;
    margin-right: 15px;
}
  .heart {
    margin-right: 5px; /* 愛心之間的間距 */
  }
  .red {
    color: red;
  }
  .black {
    color: black;
  }
  .heartChar {
    font-size: 14px; /* 調整字型大小 */
    margin-right: 5px; /* 愛心之間的間距 */
  }

.shareIcon {
    margin-top: 10px;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.postTitleColor{
    color: #1C563C;
}

.post-profile-name {
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
}


.post-profile-image {
    margin-right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%; /* 圓形圖片 */
    border: 2px solid #f58529; /* 頭像外圈顏色 (Instagram 漸層效果) */
    padding: 2px;
    background: linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4);
}

.post-profile-image-FirstName {
    display: inline-flex;
    vertical-align: middle; /* 與旁邊的文字垂直對齊 */
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 15px;
    color: white;
    background-color: #666; /* 替代顏色 */
    width: 25px;
    height: 25px;
    border: 2px solid #f58529; /* 頭像外圈顏色 (Instagram 漸層效果) */
    border-radius: 50%;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    text-align: center;
}
.btnSendCreatePost{
    background-color: #00bfa5;
}

.post-name-list-subtitle {
    font-size: 12px;
    vertical-align: text-bottom;
}

.post-create-item-title{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
    color:rgb(14, 14, 15);
}

.post-create-item-title-describe{
    font-size: 14px;
    margin-bottom: 10px;
    color:rgb(63, 63, 66);
}

.post-create-item-subtitle{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color:rgb(14, 14, 15);
}

.post-ideaheader {
    font-size: 24px;
    font-weight: bold;
}

.post-item-introduce {
    font-size: 16px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 5px;
    color: rgb(14, 14, 15);
}

.post-item-content {
    font-size: 14px;
    margin-bottom: 20px;
    color: rgb(63, 63, 66);
}